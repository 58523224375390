import styles from '../../../global.module.css';
import React, { Component } from 'react';


class index extends React.Component {
    public render() {
        return (
            
            <div className={styles.TopHeadContainer}>
                <div className={styles.TopHeadFlex}>
              
                    <div className={styles.TopHeadContainerflex1}>
                        <img src='src/images/roundsun.svg' />
                    </div>
                    <div className={styles.TopHeadContainerflex3}>
                        <h1>
                            <span className={styles.WhiteHeaderText}>Hi, I'm Dawn.</span> 
                        </h1>
            
                        <h2> <span className={styles.WhiteHeaderText}>I am UX/UI Designer and Front-End Developer.</span></h2>
              
                        <p className={styles.LightOrangeText}>I love creative problem solving.</p>
               
                
                    </div>
                    <div className={styles.TopHeadContainerflex1img}>
                        <img src='src/images/portrait1.webp' />
                    </div>
                </div>
                <div className={styles.TopHeadParagraph}>
                    <p className={styles.whiteText}>With more than 22 years of experience from concept to deployment, my approach to application design is to
                    first understand the users through research. I then balance that information with business needs
                        and use data to assess direction.</p>
                </div>

                <div className={styles.LinkButtonContainer}>

                    <span className={styles.buttonDrop}><a href="#cases" target="_new"><span className={styles.LinkButtonText}>Let's Get Started</span>
                        <svg fill="#FFF" viewBox="0 -8 32 32" className={styles.iconDrop}>
                            <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
                        </svg>
                    </a></span>
                    <span className={styles.buttonDrop}><a href="DawnZinkCV.pdf" target="_new"><span className={styles.LinkButtonText}>My Resume</span>
                        
                        <svg fill="#FFF" viewBox="0 -8 32 32" className={styles.iconDrop}>
                            <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
                        </svg>
                      
                    </a></span>

                </div>
            </div>
         
            
            
       
        );
    }
}

export default index;
