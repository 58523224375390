import * as React from 'react';
import styles, { footerStyle } from '../../../global.module.css'; 


class footer extends React.Component {
    public render() {
        return (
           

            <div className={styles.footerStyle}>
                <p>&nbsp;</p>
                &copy; Copyright 2024, All Rights Reserved.

            </div>
        );
    }
}
export default footer;