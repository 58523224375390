
import { ReactNode, useState, useEffect, useRef, createElement, memo } from 'react';
//import * as React from 'react';
import React from 'react';
import ReactDOM from "react-dom/client";
import styled from "styled-components";
import styles from '../../../global.module.css';

function caseStudies() {

    const [isCaseVisible, setIsCaseVisible] = useState(false);
    const [isCaseClosedVisible, setIsCaseClosedVisible] = useState(false);
    const [isCase1Visible, setIsCase1Visible] = useState(false);
    const [isCaseClosed1Visible, setIsCaseClosed1Visible] = useState(false);

    const handleCasePress = () => {
        setIsCaseVisible((isVisible) => !isVisible);
        setIsCaseClosedVisible(false);
    };

    const handleCaseClosedPress = () => {
        setIsCaseClosedVisible((isVisible) => !isVisible);
        setIsCaseVisible(false);
    };

    const handleCase1Press = () => {
        setIsCase1Visible((isVisible) => !isVisible);
        setIsCaseClosed1Visible(false);
    };

    const handleCaseClosed1Press = () => {
        setIsCase1Visible((isVisible) => !isVisible);
        setIsCaseClosed1Visible(false);
    };

    return (
        <>

                <div className={styles.row}>
                <div className={styles.textColumn}>


                    <Pcontainer>
                        <br /><br />
                        <h3>Swanson Health Products &mdash; Search Results</h3>
                        <p><strong>Primary KPI: </strong>Cart Conversion Rate</p>
                        <h3>Challenge</h3>
                        <p>
                        <ul>
                            <li>Simplify the search results page for Swanson Health Products.</li>
                            <li>Modernize a facet system for browsing.</li>
                            <li>Integrate additional ideas to increase conversions.</li>
                            <li>Must have AA WCAG ADA compliance</li>
                        </ul>
                        </p>
                    
                       
                    </Pcontainer>
                    
         
                </div>
                <div className={styles.textColumn}>

                    <ImgLg src="./src/images/button.webp" />

                </div>


            </div>
            <div className={styles.row}>
                <div className={styles.alignCenter}>
                <button className={styles.buttonDrop} onClick={handleCasePress}>
                    About the Process
                        <svg fill="#FFF" viewBox="0 0 24 24" className={styles.iconDrop}>
                            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z" />
                        </svg>
                    </button>
                </div>
               
            </div>
            <div className={styles.row}>
                {isCaseVisible && <Pcontainer><h3>The Process</h3>
                    <p><strong>Establish Empathy</strong></p>
                    <p>Understanding how users interact with your application is critical. For Swanson Health Products' search results pages, issues such as clutter, a long product category list, and a mobile page that was not intuitive lead to the following enhancements:
                    </p>
                    <ul>
                        <li>Dynamic facets with refinements were implemented.</li>
                        <li>Lines were removed to reduce clutter.</li>
                        <li>Colors were darkened on buttons to meet ADA regulations.</li>
                        <li>The mobile "Add to Cart"" button was simplified.</li>
                    </ul>

                    <p><strong>Iterations and Testing</strong></p>
                    <p>Continuous iterations are important. Additional changes were made to the search results pages based on A/B testing, which showed that an "Add to Cart" button with both upper- and lower-case letters was most effective with our primary demographic. Revisions were updated to the buttons.
                    </p>
                    <p>Additional features were added to the search results, such as a dynamic reviews facet and the ability to add featured imagery to the Product Level Pages (PLP) for cross-selling of similar products.
                    </p>
                    <h3>The Result</h3> 
                    <p>On the original state of the mobile search results pages, the "Call to Action" for the "Add to Cart" button was initially presented as two light vertical lines. When swiped, these lines "opened up" a button that displayed the price and added the item to the cart when clicked.</p>
                    <p>Simplifying the mobile button resulted in a 1000% increase in conversion rates for the search results pages, leading to an overall increase in conversion rates.</p> 
                      <p>&nbsp;</p>
                </Pcontainer>
                }
                {isCaseClosedVisible && <p></p>}
            </div>

            <div className={styles.row}>

                <div className={styles.textColumn}>
                    <ImgLg src="./src/images/kmap.webp" />

                </div>

                <div className={styles.textColumn}>
                   
                    <PcontainerR>
                        <h3>Krueger Construction &mdash; Plat Map 
                        </h3>
                    
                        <br />
                        <h3>Challenge</h3>
                        <p>
                            <ul>
                                <li>Interact with customers to find available plats.</li>
                                <li>Create a map that could expand on additional building opportunities.</li>
                                <li>Showcase the area for prospective individuals.</li> 
                            </ul>
                        </p>
                        <p>Can be viewed here: <a href="https://www.kruegerbuilt.com/available-lots" target="_new">https://www.kruegerbuilt.com/available-lots</a></p>
                    </PcontainerR>
                </div>
            </div>



        
                       
   
        </>
    );


}



const ImgLg = styled.img`
  max-width: 900px;
  width: 100%;
  border-radius: 30px;
`;
const Pcontainer = styled.div`
  padding: 0 2rem 5rem 0;
  width: 100%;
`;

const PcontainerR = styled.div`
  padding: 0 0 0 2rem;
   @media screen and (max-width: 996px) {
       padding: 0 0;
       }
`;
export default caseStudies;
