

declare var require: any

//var React = require('react');
var ReactDOM = require('react-dom');

import * as React from 'react';
import styled from "styled-components";
import { createRoot } from 'react-dom/client';

import Header from './src/components/header/header';
import Index from './src/components/index/index';
import Portfolio from './src/components/portfolio/portfolio';
import Footer from './src/components/footer/footer';
import CaseStudies from './src/components/caseStudies/caseStudies';
import styles, { column } from './global.module.css'; 
import MyButton from './src/components/myButton';
import { ReactNode, useState, useEffect, useRef } from 'react';

import Modal from "./src/components/modal/modal";
import useModal from "./src/components/modal/useModal";
import myButtonApp from './src/components/myButton';





//const btnRef = useRef();

//const handleShow = () => React.setOpen(true);

//const myvalue = (props) => { isOpen: false }
//const { isOpen, toggle } = useModal();
//const Foo = <Modal isOpen=0, toggle />;
//function App() {
function App () {
 
        return (
            <React.Fragment>
                <link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet' />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
               
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
              

                <div className={styles.container}>
                    <a id="home"></a>
                    <Header /> 
     
           
                    <Index />
             
                </div>    
                <a id="cases" />
                <br /><br /><br /><br />
                <div className={styles.containerMid}>
                    
                    <h2><span className={styles.blueColor}>&#9679;</span> Case Studies</h2>
                  
                    <div className={styles.borderDiv}>
                        <CaseStudies />
                    </div>

                </div>
                <a id="work" />
                <br /><br /><br /><br />
                <div className={styles.containerMid}>
                 
                  
                        
                        <h2><span className={styles.blueColor}>&#9679;</span> Recent Work</h2>
                      
                      
                    <div className={styles.borderDiv}>
                        <Portfolio />
                    </div>
                </div>
                  
                <a id="contact" />
                <br /><br /><br /><br />
                <div className={styles.containerMid}> 
                      
                        <h2><span className={styles.blueColor}>&#9679;</span> Contact Me</h2>
                   
                    <p>Fill out the form or drop me a message at: <a href="mailto:dawn.zink@gmail.com">dawn.zink@gmail.com</a></p>
                    <div className={styles.borderDiv}>
                      <br />
                     <iframe class="formape-iframe"
                        width="100%"
                        allowtransparency="true"
                        allowfullscreen="true"
                        frameborder="0"
                        title="Contact From"
                        height="765"
                        id="formape-aupqw"
                        src="https://www.formape.com/f/aupqw">
                        </iframe>
                        
                    </div>
                     
                    </div>

                <div className={styles.containerMid}>
                    <Footer />
                    <br />
                </div>
            </React.Fragment>         
           
        );
    
} 

//ReactDOM.render(<App />, document.getElementById('root'));
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
//export default App;