
import styles from '../../../global.module.css';
import React, { Component } from 'react';
import styled from "styled-components";

   
class header extends Component {
    public render() {

        return (
            
            <TopnavSticky>
               
                <div className={styles.topnav} id="myTopnav"> 
                    <span className={styles.navIcons}><a href="#home"><img src='src/images/roundsun.svg' /></a></span>
                    <span className={styles.mobileHide}><a href="#home" className={styles.topnav}>Home</a></span>
                    <a href="#cases" className={styles.topnav}>Case Studies</a>
                    <a href="#work" className={styles.topnav}>Recent Work</a>
                    <a href="#contact" className={styles.topnav}>Contact</a> 
                </div>
            </TopnavSticky>
        );
    } 
}
const TopnavSticky = styled.div`
    width: 100%;

    position: fixed;
    top: 0;
    background: #1F3864;
    z-index: 2;
`;
export default header;