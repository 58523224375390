

import { ReactNode, useState, useEffect, useRef, createElement, memo } from 'react';
//import * as React from 'react';
import React from 'react';
import ReactDOM from "react-dom/client";
import styled from "styled-components";
import styles from '../../../global.module.css';

function portfolio() {

    const [isPortVisible, setIsPortVisible] = useState(false);
    const [isCloseVisible, setIsCloseVisible] = useState(false);

    const handlePortPress = () => {
        setIsPortVisible((isVisible) => !isVisible);
        setIsCloseVisible(false);
    };

    const handleClosePress = () => {
        setIsCloseVisible((isVisible) => !isVisible);
        setIsPortVisible(false);
    };


    const [isPort2Visible, setIsPort2Visible] = useState(false);
    const [isClose2Visible, setIsClose2Visible] = useState(false);

    const handlePort2Press = () => {
        setIsPort2Visible((isVisible) => !isVisible);
        setIsClose2Visible(false);
    };

    const handleClose2Press = () => {
        setIsCloseVisible((isVisible) => !isVisible);
        setIsPort2Visible(false);
    };

    return (
        <>
            <div className={styles.row}>

                <div className={styles.textColumn}>
                 

                    <Pcontainer>
                        <PcontainerR>
                           <br/>
                            <h3>Swanson Health Products &mdash; Checkout</h3>
                            <p><strong>Primary KPI: </strong>Order Conversion Rate</p>
                            <br />
                            <h3>Challenge</h3>
                            <p>
                                <ul>
                                    <li>Streamline the experience by combining into a single page.</li>
                                    <li>Clean up the checkout header to reduce bounce rate.</li>
                                    <li>Integrate and wrap the new checkout process around third party systems that were limited at the time.</li>
                                    <li>One-page checkout to be converted to ReactJS library, as it was on legacy framework.</li>
                                    <li>Must be WCAG Level AA ADA compliant.</li>
                                </ul>
                            </p>
                        </PcontainerR>
                    </Pcontainer>



                </div>
                <div className={styles.textColumn}>
                  
                    <ImgLg src="./src/images/swansoncheckout.webp" />

                </div>


            </div>

         
            <div className={styles.row}>

                <div className={styles.textColumn}>
                    <ImgLg src="./src/images/dogsofamerica.webp" />
                </div>
            
                <div className={styles.textColumn}>
                   
                    <PcontainerR>
                        <h3>Service Dogs for America
                        </h3>

                        <br />
                        <h3>Challenge</h3>
                        <p>
                            <ul>
                                <li>Redesign a more user-friendly site.</li>
                                <li>Make it easier to maintain for in-house staff.</li>
                                <li>Revamp navigation and homepage to assist with information.</li>
                                <li>Must be WCAG Level AA ADA compliant.</li>
                            </ul>
                        </p>
                        <p>Can be viewed at <a href="https://www.servicedogsforamerica.org/" target="_new">https://www.servicedogsforamerica.org/</a></p>
                    </PcontainerR>


                </div>
            
            </div>



        </>
    );


}

const ImgLg = styled.img`
  width: 100%;
  border-radius: 30px;
`;
const Pcontainer = styled.div`
    padding: 0 2rem 0 0;
`;
const PcontainerR = styled.div`
  padding: 0 0 0 2rem;
   @media screen and (max-width: 996px) {
       padding: 0 0;
       }
`;
const PRow4Dropdown = styled.div`
  padding: 3rem 0 0 0 0;
  width: 100%
`;
export default portfolio;
